import React from 'react';
import Heroimg from '../Components/Heroimg';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Home = () => {
  return (
  <div>
  <Navbar/>
  <Heroimg/>
   
  <Footer/>
 </div>
 );
}

export default Home;
