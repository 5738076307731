import React from 'react';

const  error = () => {
  return (
    <div>
      <h1>Oops! page not found.</h1>
    </div>
  )
}

export default error;
